import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { Box, Stack, Anchor } from '../styled';

const renderers = {
	link: function ({ href, children }) {
		return (
			// TODO: detect non-external links
			<Anchor external href={href}>
				{children}
			</Anchor>
		);
	},
};

export function MakeCodeGame({ game }) {
	const embedURL = getEmbedUrl(game);

	return (
		<Stack spacing={[3]}>
			<Box maxWidth={[600]} mx="auto">
				<Anchor as={Link} to="/makecode">
					← Games
				</Anchor>
			</Box>
			<Box
				display="grid"
				gridTemplateColumns={['1fr', null, '360px 1fr']}
				gridGap={[4]}
			>
				<GameHost embedURL={embedURL} />
				<Description
					title={game.title}
					description={game.longDescription}
					forumPost={game.forumPost}
				/>
			</Box>
		</Stack>
	);
}

function GameHost({ embedURL }) {
	return (
		<Box maxWidth={400}>
			<div
				style={{
					position: 'relative',
					height: 0,
					paddingBottom: '117.6%',
					overflow: 'hidden',
				}}
			>
				<iframe
					title="makecode arcade"
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
					src={embedURL}
					allowFullScreen="allowfullscreen"
					sandbox="allow-popups allow-forms allow-scripts allow-same-origin"
					frameBorder="0"
				></iframe>
			</div>
		</Box>
	);
}

function Description({ title, description, forumPost }) {
	return (
		<Stack>
			<Box as="header" fontWeight="bold" fontSize={[4]}>
				{title}
			</Box>
			<Box>
				<Anchor external href={forumPost}>
					forum thread
				</Anchor>
			</Box>
			<Box mt={[4]}>
				<ReactMarkdown
					source={description}
					escapeHtml={false}
					renderers={renderers}
				/>
			</Box>
		</Stack>
	);
}

function getEmbedUrl({ gitHubProject, shareId }) {
	if (gitHubProject) {
		const [owner, project] = gitHubProject.split('/');
		return `https://${owner}.github.io/${project}/?embed=1&nofooter=1`;
	}

	return `https://arcade.makecode.com/---run?id=${shareId}&nofooter=1&embed=1`;
}
